import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Box from "../Box/Box";
import { CollectionQuery } from "../../types/pages/main";
import { get } from "../../constants/theme";
import { Paragraph, Subtitle, Title } from "../Styled/Text";
import LinkArrowBtn from "../LinkArrowBtn";
import { Routes } from "../../routes";
import { SectionWrapper2 } from "../Styled/Containerts";
import { clampBuilder } from "../../helpers/sizeCalc";
import Appear from "../Animations/AppearContainer";

interface Props {}
const Collection = (
  _: Props,
  ref: React.ForwardedRef<HTMLDivElement | null>
) => {
  const { description, link, subtitle, title } =
    useStaticQuery<CollectionQuery>(graphql`
      query MainCollectionQuery {
        translationsJson {
          pages {
            main {
              sections {
                collection {
                  description
                  link
                  title
                  subtitle
                }
              }
            }
          }
        }
      }
    `).translationsJson.pages.main.sections.collection;

  return (
    <Box sx={{ position: "relative", zIndex: 7 }} ref={ref}>
      <SectionWrapper2
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: ["center"],
          justifyContent: "center",
          textAlign: "center",
          background: get("palette.background.gold"),
          "& > h1,p": {
            marginLeft: 0,
          },
        }}
      >
        <Appear wrapperCmp={Subtitle} triggerOffset={50}>
          {subtitle}
        </Appear>

        <Appear wrapperCmp={Title} triggerOffset={50} variant="section">
          {title.split(" ")[0]}
          <br />
          {title.split(" ").pop()}
        </Appear>

        <Appear
          wrapperCmp={Paragraph}
          triggerOffset={50}
          sx={{
            width: [
              "unset",
              "unset",
              clampBuilder(599, 1680, 19, 40),
              clampBuilder(599, 1680, 19, 40),
            ],
            color: get("palette.text.brown"),
            marginBottom: [
              clampBuilder(320, 599, 3.375, 6.6875),
              clampBuilder(320, 599, 3.375, 6.6875),
              clampBuilder(599, 1680, 1.75, 4.875),
              clampBuilder(599, 1680, 1.75, 4.875),
            ],
          }}
        >
          {description}
        </Appear>

        <Appear wrapperCmp={Box}>
          <LinkArrowBtn to={Routes.COLLECTION} title={link} as={Link} />
        </Appear>
      </SectionWrapper2>
    </Box>
  );
};

export default React.memo(React.forwardRef<HTMLDivElement, Props>(Collection));
