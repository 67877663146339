import { useTransform, useViewportScroll } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled, { keyframes } from "styled-components";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { SliderQuery } from "../types/pages/main";
import Box from "./Box/Box";
import MotionBox from "./Box/MotionBox";
import GatsbyImageWithArtDirection from "./GatsbyImageWithArtDirection";
import { Paragraph2, Title } from "./Styled/Text";

const animation = keyframes`
  0% {
    opacity: 0.0;
  }
  8% {
    opacity: 1.0;
  }
  33.33% {
    opacity: 1.0;
    transform: scale(1.1);
  }
  41.33% {
    opacity: 0.0;
  }
  100% {
    opacity: 0.0; 
    transform: scale(1);
  }
`;

const SliderImage = styled("div")<{ order: number }>`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: calc(100vh - var(--vh-offset, 0px));
  opacity: ${(p) => (p.order === 0 ? 1 : 0)};
  background-position: 50% 50%;
  background-size: cover;
  animation: ${animation} 24s ${(p) => 24 * 0.33 * p.order}s infinite linear;
`;

type Props = {
  images: Array<{
    id: string;
    desktopImage: IGatsbyImageData;
    mobileImage: IGatsbyImageData;
    alt: string;
  }>;
  isPreloaderActive: boolean;
};
const Slider = ({ images, isPreloaderActive }: Props) => {
  const { height } = useWindowSize();
  const { title, description } = useStaticQuery<SliderQuery>(graphql`
    query MainPageHeaderQuery {
      translationsJson {
        pages {
          main {
            slider {
              title
              description
            }
          }
        }
      }
    }
  `).translationsJson.pages.main.slider;

  const { scrollY } = useViewportScroll();
  const scale = useTransform(scrollY, [0, height], [1, 0.85]);

  // const images = React.useMemo(() => {
  //   if (width <= 599) {
  //     return ["/dhbi89.jpg", "/dijo8shj.jpg", "/odkpjhc7.jpg"];
  //   } else {
  //     return ["/32435268.jpg", "/d45ae176.jpg", "/053bb9d2.jpg"];
  //   }
  // }, [width]);

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        minHeight: "calc(100vh - var(--vh-offset,0px))",
        maxHeight: "calc(100vh - var(--vh-offset,0px))",
        width: "100%",
      }}
    >
      {/* <Box
        sx={{
          width: 0,
          height: 0,
          zIndex: -1000,
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0,
          visibility: "hidden",
        }}
      >
        {[
          "/dhbi89.jpg",
          "/dijo8shj.jpg",
          "/odkpjhc7.jpg",
          "/32435268.jpg",
          "/d45ae176.jpg",
          "/053bb9d2.jpg",
        ].map((image, i) => (
          <img key={i} src={image} alt={""} />
        ))}
      </Box> */}
      <Box
        sx={{
          position: "absolute",
          zIndex: 5,
          color: `#fff`,
          width: "90%",
          top: ["25%", "25%", "50%", "50%"],
          left: "50%",
          transform: "translateY(-50%) translateX(-50%)",
          textAlign: ["center", "center", "left", "left"],
        }}
      >
        {title.map((sentence, i) => (
          <MotionBox
            sx={
              i === title.length - 1
                ? {
                    marginBottom: [
                      0,
                      0,
                      clampBuilder(599, 1680, 2.4375, 4.275),
                      clampBuilder(599, 1680, 2.4375, 4.275),
                    ], //103 39
                  }
                : {}
            }
            key={i}
            initial={{ y: "100%", opacity: 0 }}
            animate={{
              y: isPreloaderActive ? "100%" : 0,
              opacity: isPreloaderActive ? 0 : 1,
              transition: {
                duration: 2,
                ease: "easeOut",
                delay: 0.25 * i,
              },
            }}
            style={{ scale: scale as any }}
          >
            <Title
              variant="hero"
              sx={{ color: "inherit", textAlign: "inherit" }}
            >
              {sentence}
            </Title>
          </MotionBox>
        ))}
        <MotionBox
          sx={{ display: ["none", "none", "block", "block"] }}
          initial={{ y: "100%", opacity: 0 }}
          animate={{
            y: isPreloaderActive ? "100%" : 0,
            opacity: isPreloaderActive ? 0 : 1,
            transition: {
              duration: 2,
              ease: "easeOut",
              delay: 0.25 * (title.length + 1),
            },
          }}
          style={{ scale: scale as any }}
        >
          <Paragraph2
            variant="hero"
            sx={{
              color: "#fff",
              opacity: 1,
              textAlign: ["left"],
              display: ["none", "none", "block", "block"],
            }}
          >
            {description}
          </Paragraph2>
        </MotionBox>
      </Box>

      {images.map((image, i) => (
        <SliderImage key={image.id} order={i}>
          <GatsbyImageWithArtDirection
            {...image}
            sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
          />
        </SliderImage>
      ))}
    </Box>
  );
};

export default React.memo(Slider);
