import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { get } from "../../constants/theme";
import Box from "../Box/Box";
import { SectionWrapper2 } from "../Styled/Containerts";
import { Subtitle, Title } from "../Styled/Text";
import Appear from "../Animations/AppearContainer";
import { MainConfiguratorQuery } from "../../types/pages/main";
import ConfiguratorIframeBox from "../Styled/ConfiguratorIframe";

interface Props {
  iframeHeight?: number | undefined;
}
const Configurator = ({ iframeHeight }: Props) => {
  const [isIframeVisible, setIsIframeVisible] = React.useState<
    "iframe-not-visible" | "iframe-visible"
  >("iframe-not-visible");

  const { subtitle, title, locale } =
    useStaticQuery<MainConfiguratorQuery>(graphql`
      query MainConfiguratorQuery {
        translationsJson {
          pages {
            main {
              sections {
                configurator {
                  subtitle
                  title
                  locale
                }
              }
            }
          }
        }
      }
    `).translationsJson.pages.main.sections.configurator;

  const handleIframeInterViewport = React.useCallback(() => {
    setIsIframeVisible("iframe-visible");
  }, []);

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - var(--vh-offset,0px))",
          position: "relative",
          WebkitOverflowScrolling: "touch",
          zIndex: 7,
        }}
      >
        <SectionWrapper2
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: ["center"],
            justifyContent: "center",
            textAlign: "center",
            background: get("palette.background.gold"),
            "& > h1,p": {
              marginLeft: 0,
            },
          }}
        >
          <Appear wrapperCmp={Subtitle}>{subtitle}</Appear>
          {title.map((t, i) => (
            <Appear wrapperCmp={Title} key={i} variant="section">
              {t}
            </Appear>
          ))}
        </SectionWrapper2>
        <ConfiguratorIframeBox
          key={isIframeVisible}
          viewport={{ once: true }}
          onViewportEnter={handleIframeInterViewport}
          $iframeHeight={iframeHeight}
          sx={{
            background: get("palette.background.gold"),
          }}
          src={`https://angry-sinoussi-400096.netlify.app/?locale=${
            locale ? locale : "en_EN"
          }`}
        />
      </Box>
    </>
  );
};

export default React.memo(Configurator);
