import React from "react";

const useCssVariableValue = (variableName: string) => {
  const [value, setValue] = React.useState<string | undefined>(undefined);

  const getVarValue = React.useCallback(() => {
    var style = getComputedStyle(document.body);
    setValue(style.getPropertyValue(variableName));
  }, [variableName]);

  React.useEffect(() => {
    getVarValue();
  }, [getVarValue]);

  return value;
};

export default useCssVariableValue;
