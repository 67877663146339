import { easeInOut, linear } from "@popmotion/easing";
import {
  AnimatePresence,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { ratioCalc } from "../../../../../helpers/ratioCalc";
import useWindowSize from "../../../../../hooks/useWindowSize";
import Box from "../../../../Box/Box";
import MotionBox from "../../../../Box/MotionBox";

type Props = {
  imgAlt: string;
  img: IGatsbyImageData;
  imgContainerSize: {
    width: number;
    height: number;
  };
  imgOriginalSize: {
    width: number;
    height: number;
  };
};

const HistoryAnimatedCmp = ({
  img,
  imgAlt,
  imgOriginalSize,
  imgContainerSize,
}: Props) => {
  const [isFixed, setIsFixed] = React.useState<boolean>(false);
  const { height, width } = useWindowSize();
  const { scrollY } = useViewportScroll();
  const [offset, setOffset] = React.useState<number>(0);

  const imgRef = React.useCallback((node: any | null) => {
    if (node !== null) {
      setOffset(node.offsetTop);
    }
  }, []);

  const marginTop = useTransform(
    scrollY,
    [offset - height / 2 + imgContainerSize.height / 2, offset],
    [0, 0]
  );

  const top = useTransform(scrollY, [offset, offset + 500], [0, height / 2]);
  const marginTopFixed = useTransform(
    scrollY,
    [offset, offset + 500],
    [0, -imgContainerSize.height / 2]
  );

  const translateX = useTransform(
    scrollY,
    [offset, offset + 500],
    [0, ((width - width * 0.05) / 6 + imgContainerSize.width) / 2]
  );

  const translateY = useTransform(scrollY, [offset, offset + 500], [0, 0], {
    ease: linear,
  });

  const scale = useTransform(
    scrollY,
    [offset, offset + 500, offset + 1150, offset + 1300],
    [
      1,
      height / imgContainerSize.height,
      height / imgContainerSize.height,
      (height * 0.9) / imgContainerSize.height,
    ],
    { ease: easeInOut }
  );

  const zIndex = useTransform(
    scrollY,
    [
      offset - height,
      offset - imgOriginalSize.height / 2,
      offset,
      offset + 1349,
      offset + 1350,
    ],
    [-1, 2, 2, 2, -1]
  );

  const opacity = useTransform(
    scrollY,
    [offset - height - 1, offset - height, offset + 1200, offset + 1350],
    [0, 1, 1, 0]
  );

  React.useEffect(() => {
    const scrollSub = scrollY.onChange((v) => {
      if (v >= offset) {
        setIsFixed(true);
      } else {
        isFixed && setIsFixed(false);
      }
    });
    return () => {
      scrollSub();
    };
  }, [scrollY, height, isFixed, offset]);

  const checkOffset = React.useCallback(
    (v) => {
      const newOffset = v.target.parentNode.offsetTop;
      if (newOffset !== offset) setOffset(newOffset);
    },
    [offset]
  );

  return (
    <AnimatePresence exitBeforeEnter>
      <MotionBox
        onViewportEnter={checkOffset}
        ref={imgRef}
        exit={{ transition: { duration: 1 } }}
        layout
        transition={{ layout: { duration: 0.3, ease: "linear" } }}
        sx={{
          height: imgContainerSize.height,
          width: imgContainerSize.width,
        }}
        style={{
          top: top as any,
          opacity: opacity as any,
          x: translateX as any,
          y: isFixed ? 0 : translateY,
          scale: scale as any,
          position: isFixed ? "fixed" : "unset",
          zIndex: zIndex as any,
          willChange: "auto",
          marginTop: isFixed ? (marginTopFixed as any) : (marginTop as any),
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            aspectRatio: `${ratioCalc({
              width: imgOriginalSize.width,
              height: imgOriginalSize.height,
            })}`,
            objectFit: "contain",
          }}
          imgStyle={{ objectFit: "contain" }}
          as={GatsbyImage}
          alt={imgAlt}
          image={img}
          loading="eager"
        />
      </MotionBox>
    </AnimatePresence>
  );
};

export default React.memo(HistoryAnimatedCmp);
