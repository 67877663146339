import { easeInOut } from "@popmotion/easing";
import {
  AnimatePresence,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { ratioCalc } from "../../../../../helpers/ratioCalc";
import useCssVariableValue from "../../../../../hooks/useCssVariableValue";
import useWindowSize from "../../../../../hooks/useWindowSize";
import Box from "../../../../Box/Box";
import MotionBox from "../../../../Box/MotionBox";

type Props = {
  imgAlt: string;
  img: IGatsbyImageData;
  imgOriginalSize: {
    width: number;
    height: number;
  };
};
const HistoryAnimatedCmp = ({ img, imgAlt, imgOriginalSize }: Props) => {
  const { width, height } = useWindowSize();
  const { scrollY } = useViewportScroll();
  const [isFixed, setIsFixed] = React.useState<boolean>(false);
  const [offset, setOffset] = React.useState<number>(0);
  const isIosOffsetValue = useCssVariableValue("--vh-offset");
  const iosOffsetValue = React.useMemo(() => {
    if (isIosOffsetValue) {
      if (/^\d+px$/.test(isIosOffsetValue)) {
        return Number(isIosOffsetValue.slice(0, isIosOffsetValue.length - 2));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [isIosOffsetValue]);

  const imgRef = React.useCallback((node: any | null) => {
    if (node !== null) {
      setOffset(node.offsetTop);
      setImgContainerSize({
        width: node.clientWidth,
        height: node.clientHeight,
      });
    }
  }, []);

  const [imgContainerSize, setImgContainerSize] = React.useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const marginTop = useTransform(
    scrollY,
    [offset - (height - imgContainerSize.height) / 2, offset + 250],
    [0, 0 + Math.round(imgContainerSize.height)]
  );

  const marginTopFixed = useTransform(
    scrollY,
    [offset, offset + 250],
    [-imgContainerSize.height / 2, -imgContainerSize.height / 2]
  );

  const scale = useTransform(
    scrollY,
    [
      offset - (height - imgContainerSize.height) / 2,
      offset + 250,
      offset + 1150,
      offset + 1300,
    ],
    [
      1,
      height / imgContainerSize.height,
      height / imgContainerSize.height,
      (0.85 * height) / imgContainerSize.height,
    ],
    { ease: easeInOut }
  );

  const zIndex = useTransform(
    scrollY,
    [offset - imgOriginalSize.height / 2, offset + 1349, offset + 1350],
    [2, 2, -1]
  );

  const opacity = useTransform(
    scrollY,
    [
      offset - height - 1 - iosOffsetValue,
      offset - height - iosOffsetValue,
      offset + 1300,
      offset + 1350,
    ],
    [0, 1, 1, 0]
  );

  React.useEffect(() => {
    const scrollSub = scrollY.onChange((v) => {
      if (v >= offset - (height - imgContainerSize.height) / 2) {
        setIsFixed(true);
      } else {
        isFixed && setIsFixed(false);
      }
    });
    return () => {
      scrollSub();
    };
  }, [scrollY, height, isFixed, offset, imgContainerSize.height]);

  const checkOffset = React.useCallback(
    (v) => {
      const newOffset = v.target.parentNode.offsetTop;
      if (newOffset !== offset) setOffset(newOffset);
    },
    [offset]
  );

  return (
    <AnimatePresence exitBeforeEnter>
      <MotionBox
        ref={imgRef as any}
        onViewportEnter={checkOffset}
        exit={{ transition: { duration: 1 } }}
        layout="size"
        key="jasperVase"
        layoutId="vase"
        sx={{
          height: 374,
          width: 261,
          aspectRatio: `${ratioCalc({
            width: imgOriginalSize.width,
            height: imgOriginalSize.height,
          })}`,
        }}
        style={{
          top: isFixed
            ? `${height / 2}px`
            : "calc(0px + var(--vh-offset, 0px))",
          opacity: opacity as any,
          scale: scale as any,
          position: isFixed ? "fixed" : "unset",
          zIndex: zIndex as any,
          willChange: "auto",
          left: `${width / 2}px`,

          marginTop: isFixed ? (marginTopFixed as any) : (marginTop as any),
          marginRight: isFixed ? "0" : "auto",
          marginLeft: isFixed ? `-${imgContainerSize.width / 2}px` : "auto",
          marginBottom: 0,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            aspectRatio: `${ratioCalc({
              width: imgOriginalSize.width,
              height: imgOriginalSize.height,
            })}`,
            objectFit: "contain",
          }}
          imgStyle={{ objectFit: "contain" }}
          as={GatsbyImage}
          alt={imgAlt}
          image={img}
          loading="eager"
        />
      </MotionBox>
    </AnimatePresence>
  );
};

export default React.memo(HistoryAnimatedCmp);
