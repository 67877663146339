import React from "react";
import { useLocation } from "@reach/router";

function useRemovePreloader(el: HTMLElement | undefined) {
  const isMounted = React.useRef<boolean>();
  const removeTimeout = React.useRef<NodeJS.Timeout>();
  const isPreloaderWasActiveBefore = React.useRef<boolean>(false);
  const preloaderContent = React.useRef<Element[]>([]);
  const { pathname } = useLocation();
  const [isPreloaderActive, setIsPreloaderActive] =
    React.useState<boolean>(true);

  const handleAddPreloader = React.useCallback(() => {
    isPreloaderWasActiveBefore.current = true;
    el && el.classList.add("preloading");
    document.body.classList.add("preloader_active");
    preloaderContent.current.forEach((c, i) => {
      setTimeout(() => {
        c.classList.add("slideUp");
      }, 550 * i);
    });
  }, [el]);

  const handleRemovePreloader = React.useCallback(() => {
    removeTimeout.current = setTimeout(() => {
      if (el && el.classList.contains("preloading")) {
        el && el.classList.remove("preloading");
      }
      document.body.classList.remove("preloader_active");
      document.body.classList.remove("preloader_ready");
      preloaderContent.current.forEach((c) => c.classList.remove("slideUp"));
      isMounted.current && setIsPreloaderActive(false);
    }, 3100);
  }, [el]);

  React.useLayoutEffect(() => {
    const contentContainer = document.querySelector(".preloader_content");
    if (contentContainer) {
      preloaderContent.current = Array.from(contentContainer.children);
    }
  }, []);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (pathname === "/" && !isPreloaderWasActiveBefore.current) {
      isMounted.current && handleAddPreloader();
    }

    isMounted.current && handleRemovePreloader();

    return () => {
      if (removeTimeout.current) window.clearTimeout(removeTimeout.current);
    };
  }, [pathname, handleAddPreloader, handleRemovePreloader, isPreloaderActive]);

  return { isPreloaderActive };
}

export default useRemovePreloader;
