import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { get } from "../../constants/theme";
import { clampBuilder } from "../../helpers/sizeCalc";
import { ManufacturingQuery } from "../../types/pages/main";
import Appear from "../Animations/AppearContainer";
import Box from "../Box/Box";
import { SectionWrapper2 } from "../Styled/Containerts";
import { Paragraph, Subtitle, Title } from "../Styled/Text";
import GridSection from "../GridSection";
import Wrapper from "../Wrapper";


type Props = {};

const Manufacturing = (props: Props) => {
  const { subtitle, title, description, header, footer, sections } =
    useStaticQuery<ManufacturingQuery>(graphql`
      query ManafacturingQuery {
        translationsJson {
          pages {
            main {
              sections {
                manufacturing {
                  description
                  footer {
                    description
                    image {
                      id
                      name
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageAlt
                    title
                  }
                  header {
                    imageAlt
                    image {
                      id
                      name
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  sections {
                    description
                    imageAlt
                    title
                    image {
                      id
                      name
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  subtitle
                  title
                }
              }
            }
          }
        }
      }
    `).translationsJson.pages.main.sections.manufacturing;

  return (
    <>
      <Box
        sx={{
          background: get("palette.background.primary"),
        }}
      >
        <SectionWrapper2 sx={{ width: "100%" }}>
          <Appear wrapperCmp={Subtitle}>{subtitle}</Appear>
          <Appear
            wrapperCmp={Title}
            sx={{ textAlign: ["center", "center", "center", "center"] }}
            variant="section"
          >
            {title.split(" ")[0]}
            <br />
            {title.split(" ").pop()}
          </Appear>
          <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
          <Appear
            wrapperCmp={Paragraph}
            sx={{
              width: [
                "unset",
                "unset",
                clampBuilder(599, 1680, 19, 40),
                clampBuilder(599, 1680, 19, 40),
              ],
              textAlign: ["center", "center", "center", "center"],
              color: get("palette.text.brown"),
            }}
          >
            {description}
          </Appear>
          </Box>
        </SectionWrapper2>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 5,
          }}
        >
          <Box
            sx={{
              width: [
                clampBuilder(320, 599, 16.125, 31),
                clampBuilder(320, 599, 16.125, 31),
                clampBuilder(599, 1680, 30.125, 65.75),
                clampBuilder(599, 1680, 30.125, 65.75),
              ],
              height: [
                clampBuilder(320, 599, 10.6875, 20.375),
                clampBuilder(320, 599, 10.6875, 20.375),
                clampBuilder(599, 1680, 20, 43.125), // 496 326 // 258 171
                clampBuilder(599, 1680, 20, 43.125),
              ],
              marginLeft: [
                0,
                0,
                0,
                0,
              ],
              marginBottom: [
                clampBuilder(599, 1680, 7, 12.8125),
                clampBuilder(599, 1680, 7, 12.8125),
              ],
            }}
          >
            <Box
              sx={{ width: "100%", height: "100%" }}
              as={GatsbyImage} // 1052 690 // 482 320
              image={header.image.childImageSharp.gatsbyImageData}
              alt={header.imageAlt}
              loading="eager"
            />
          </Box>
        </Box>
        <Box
          sx={{
            paddingBottom: [0, 0, "10rem", "10rem"],
          }}
        >
          <Wrapper>
          <GridSection
            firstChildrenType="textBox"
            secondChildrenType="imageBox"
          >
            <>
              <Title
                sx={{
                  alignSelf: "center",

                  textAlign: "center",
                  width: "100%",
                }}
                variant="paragraph"
              >
                {sections[0].title}
              </Title>
              <Paragraph
                sx={{
                  textAlign: "center",

                  alignSelf: "center",
                  width: "100%",
                }}
              >
                {sections[0].description}
              </Paragraph>
            </>
            <>
              <Box
                as={GatsbyImage}
                sx={{}}
                alt={sections[0].imageAlt}
                image={sections[0].image.childImageSharp.gatsbyImageData}
              />
            </>
          </GridSection>

          <GridSection
            firstChildrenType="imageBox"
            secondChildrenType="textBox"
          >
            <>
              <Box
                as={GatsbyImage}
                sx={{marginBottom: 0}}
                alt={sections[1].imageAlt}
                image={sections[1].image.childImageSharp.gatsbyImageData}
              />
            </>
            <>
              <Title
                sx={{
                  alignSelf: "center",

                  textAlign: "center",
                  width: "100%",
                }}
                variant="paragraph"
              >
                {sections[1].title}
              </Title>
              <Paragraph
                sx={{
                  textAlign: "center",

                  alignSelf: "center",
                  //width: "100%",
                }}
              >
                {sections[1].description}
              </Paragraph>
            </>
          </GridSection>
          </Wrapper>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(Manufacturing);
