import { SystemStyleObject } from "@styled-system/css";
import { MotionValue, useTransform, useViewportScroll } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Paragraph } from "../Styled/Text";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { PresentationQuery } from "../../types/pages/main";
import Box from "../Box/Box";
import MotionBox from "../Box/MotionBox";

interface AnimationProps {
  isLeading?: boolean;
  offset: number;
  image: any;
  title: string;
  scrollY: MotionValue<number>;
  elmViewportPosition: {
    start: number;
    end: number;
  };
  sx?: SystemStyleObject;
}

const Ring = React.memo(
  React.forwardRef(
    (
      {
        scrollY,
        elmViewportPosition,
        image,
        title,
        sx,
        offset,
        isLeading = false,
      }: AnimationProps,
      ref: any
    ) => {
      const { height, width } = useWindowSize();

      const zIndex = useTransform(scrollY, [offset, offset + 1], [-1, 6]);

      const scale = useTransform(
        scrollY,
        isLeading
          ? [elmViewportPosition.start - 150, elmViewportPosition.end + 100]
          : [offset - 50, offset],
        isLeading
          ? [width < 768 ? 0.65 : 1, width < 768 ? 1 : 1.05]
          : [width < 768 ? 0.75 : 1, width < 768 ? 1 : 1.05]
      );

      const opacity = useTransform(
        scrollY,
        isLeading
          ? [
              elmViewportPosition.start + 50,
              elmViewportPosition.start + 100,
              elmViewportPosition.end + 200,
              elmViewportPosition.end + 414,
            ]
          : [
              elmViewportPosition.start - 10,
              elmViewportPosition.start + 75,
              elmViewportPosition.end + 75,
            ],

        isLeading ? [0, 1, 1, 0] : [0, 1, 0]
      );

      return (
        <MotionBox
          ref={ref}
          style={{
            opacity: opacity as any,
            scale: scale as any,
            translateX: "-50%",
            // translateY: `-2055px`,
            zIndex: zIndex as any,
          }}
          sx={{
            position: "fixed",
            width: "297px",
            height: "297px",
            left: "50%",
            top: `${height / 2}px`,
            marginTop: "-148.5px",
            ...sx,
          }}
        >
          <Box as={GatsbyImage} image={image} alt="" loading="eager" />
          <Paragraph
            sx={{
              textAlign: "center",
              textTransform: "uppercase",
              paddingTop: "30px",
            }}
          >
            {title}
          </Paragraph>
        </MotionBox>
      );
    }
  )
);

interface WatchProps extends AnimationProps {
  position: "middle" | "left" | "right";
}

const Watch = React.memo(
  React.forwardRef<HTMLElement, WatchProps>(
    (
      { scrollY, elmViewportPosition, image, title, sx, position }: WatchProps,
      ref: React.ForwardedRef<HTMLElement | null>
    ) => {
      const { height } = useWindowSize();
      const [marg, setMarg] = React.useState<number>(0);
      const [scrollDiff, setScrollDiff] = React.useState<number>(0);

      const opacity = useTransform(
        scrollY,
        [elmViewportPosition.start, elmViewportPosition.end + 200],
        [0, 1]
      );

      const watchHeight = useTransform(
        scrollY,
        [
          elmViewportPosition.start - 200,
          elmViewportPosition.start,
          elmViewportPosition.end + 200,
        ],
        [0, 609, 467]
      );

      const width = useTransform(
        scrollY,
        [
          elmViewportPosition.start - 200,
          elmViewportPosition.start,
          elmViewportPosition.end + 200,
        ],
        [0, 382.65, 293]
      );

      const marginTop = useTransform(
        scrollY,
        [
          elmViewportPosition.start,
          elmViewportPosition.end + 200,
          elmViewportPosition.end + 297,
        ],
        [-marg / 2, -marg / 2, scrollDiff]
      );

      const { left, right, transform } = React.useMemo(() => {
        switch (position) {
          case "right":
            return { right: "10%", transform: undefined };
          case "left":
            return { left: "10%", transform: undefined };
          case "middle":
            return { left: "50%", transform: "translateX(-50%)" };
        }
      }, [position]);

      React.useEffect(() => {
        const heighSub = watchHeight.onChange((v) => {
          setMarg(v);
        });
        const unsubcribe = scrollY.onChange((v) => {
          setScrollDiff(elmViewportPosition.end - v);
        });
        return () => {
          unsubcribe();
          heighSub();
        };
      }, [scrollY, elmViewportPosition.end, watchHeight]);

      return (
        <MotionBox
          ref={ref as any}
          style={{
            opacity: opacity as any,
            width: width as any,
            height: watchHeight as any,
            marginTop: marginTop as any,
          }}
          sx={{
            position: "fixed",
            top: `${height / 2}px`,
            left: left,
            right: right,
            transform: `${transform ? transform : ""}`,
            ...sx,
          }}
        >
          <Box as={GatsbyImage} image={image} alt="" loading="eager" />
          <Paragraph
            sx={{
              textAlign: "center",
              textTransform: "uppercase",
              paddingTop: "30px",
            }}
          >
            {title}
          </Paragraph>
        </MotionBox>
      );
    }
  )
);

interface Props {
  vaseEnding: number;
}

const RingsCollection = (
  { vaseEnding }: Props,
  ref: React.ForwardedRef<(HTMLDivElement | SVGElement) | null>
) => {
  const { scrollY } = useViewportScroll();
  const [offset, setOffset] = React.useState<number>(0);
  const { height, width } = useWindowSize();

  // const containerRef = React.useCallback(
  //   (node: HTMLDivElement | null) => {
  //     if (node !== null) {
  //     }
  //   },
  //   [vaseEnding]
  // );

  React.useEffect(() => {
    setOffset(vaseEnding - 350);
  }, [vaseEnding, width, height]);

  const bg = useTransform(
    scrollY,
    [offset, offset + 594],
    ["#fff", "rgb(248,245,240)"]
  );

  const { presentation, watches } = useStaticQuery<PresentationQuery>(graphql`
    query PresentationQuery {
      translationsJson {
        pages {
          main {
            sections {
              presentation {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                order
              }
              watches {
                left {
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  imageAlt
                }
                middle {
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  imageAlt
                }
                right {
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  imageAlt
                }
              }
            }
          }
        }
      }
    }
  `).translationsJson.pages.main.sections;

  return (
    <MotionBox ref={ref as any} style={{ background: bg as any }}>
      <MotionBox
        sx={{
          height:
            width < 768
              ? `${
                  297 * (presentation.length + 1) + 594 + height + 297 - 350
                }px`
              : `${
                  297 * (presentation.length + 1) + 594 + height + 297 - height
                }px`,
          minHeight:
            width < 768
              ? `${
                  297 * (presentation.length + 1) + 594 + height + 297 - 350
                }px`
              : `${
                  297 * (presentation.length + 1) + 594 + height + 297 - height
                }px`,
          width: "100%",
        }}
      >
        {presentation.map((n, i) => (
          <Ring
            isLeading={i === 0}
            offset={offset}
            key={i}
            image={n.image.childImageSharp.gatsbyImageData}
            scrollY={scrollY}
            elmViewportPosition={{
              start: i === 0 ? offset - 297 * i : offset + 297 * i + 594,
              end:
                i === 0 ? offset + 594 * (i + 1) : offset + 297 * (i + 1) + 594,
            }}
            title={""}
          />
        ))}
        <Box>
          <Watch
            offset={offset}
            position="left"
            sx={{ display: ["none", "none", "none", "block"] }}
            image={watches.left.image.childImageSharp.gatsbyImageData}
            scrollY={scrollY}
            elmViewportPosition={{
              start: offset + 297 * presentation.length + 594,
              end:
                width < 768
                  ? offset + 297 * (presentation.length + 1) + 594
                  : offset + 297 * (presentation.length + 1) + 750,
            }}
            title={watches.left.title}
          />
          <Watch
            offset={offset}
            position="middle"
            image={watches.middle.image.childImageSharp.gatsbyImageData}
            scrollY={scrollY}
            elmViewportPosition={{
              start: offset + 297 * presentation.length + 445.5,
              end:
                width < 768
                  ? offset + 297 * (presentation.length + 1) + 594
                  : offset + 297 * (presentation.length + 1) + 750,
            }}
            sx={{}}
            title={watches.middle.title}
          />
          <Watch
            offset={offset}
            position="right"
            sx={{ display: ["none", "none", "none", "block"] }}
            image={watches.right.image.childImageSharp.gatsbyImageData}
            scrollY={scrollY}
            elmViewportPosition={{
              start: offset + 297 * presentation.length + 594,
              end:
                width < 768
                  ? offset + 297 * (presentation.length + 1) + 594
                  : offset + 297 * (presentation.length + 1) + 750,
            }}
            title={watches.right.title}
          />
        </Box>
      </MotionBox>
    </MotionBox>
  );
};

export default React.memo(
  React.forwardRef<HTMLDivElement | SVGElement, Props>(RingsCollection)
);
