import css, { SystemStyleObject } from "@styled-system/css";
import { useTransform, useViewportScroll } from "framer-motion";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import styled from "styled-components";
import { variant } from "styled-system";
import { SxProp } from "../constants/sx";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import Box from "./Box/Box";
import MotionBox from "./Box/MotionBox";

const CollageImageWrapper = styled(MotionBox)<
  SxProp & { variant: "big" | "small" }
>`
  ${(p) =>
    variant({
      variants: {
        big: {
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: [
            "clamp(16rem, -0.1290rem + 80.6452vw, 30.0625rem)",
            "clamp(16rem, -0.1290rem + 80.6452vw, 30.0625rem)",
            "clamp(30rem, 10.1557rem + 53.0065vw, 65.8125rem)",
            "clamp(30rem, 10.1557rem + 53.0065vw, 65.8125rem)",
          ],
          height: [
            "clamp(10.375rem, -0.3060rem + 53.4050vw, 19.6875rem)",
            "clamp(10.375rem, -0.3060rem + 53.4050vw, 19.6875rem)",
            "clamp(19.8125rem, 6.8947rem + 34.5051vw, 43.125rem)",
            "clamp(19.8125rem, 6.8947rem + 34.5051vw, 43.125rem)",
          ],
        },
        small: {
          width: [
            "clamp(8rem, -0.0287rem + 40.1434vw, 15rem)",
            "clamp(8rem, -0.0287rem + 40.1434vw, 15rem)",
            "clamp(15rem, 7.9004rem + 18.9639vw, 27.8125rem)",
            "clamp(15rem, 7.9004rem + 18.9639vw, 27.8125rem)",
          ],
          height: [
            "clamp(11.375rem, -0.0228rem + 56.9892vw, 21.3125rem)",
            "clamp(11.375rem, -0.0228rem + 56.9892vw, 21.3125rem)",
            "clamp(18.125rem, 6.1422rem + 32.0074vw, 39.75rem)",
            "clamp(18.125rem, 6.1422rem + 32.0074vw, 39.75rem)",
          ],
          top: 0,
          right: 0,
          transform: `translateY(50%)`,
        },
      },
    })}
  position: absolute;
  display: flex;
  flex-direction: column;

  ${(p) => p.sx && css({ ...p.sx })}
`;
type Props = {
  firstImage: {
    image: IGatsbyImageData;
    alt: string;
    description: string;
  };
  secondImage: {
    image: IGatsbyImageData;
    alt: string;
    description: string;
  };
  sx: SystemStyleObject;
};

const Collage = ({ firstImage, secondImage, sx }: Props) => {
  const { height } = useWindowSize();
  const [smallImgOffset, setSmallImgOffset] = React.useState<number>(0);

  const collageRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setSmallImgOffset(node.offsetTop);
    }
  }, []);

  const { scrollY } = useViewportScroll();

  const translateY = useTransform(
    scrollY,
    [smallImgOffset - height + 230, smallImgOffset - height + height * 0.75],
    ["60%", "40%"]
  );

  return (
    <Box
      sx={{
        padding: "0 5%",
        minHeight: [clampBuilder(599, 1680, 30.4375, 63)],
        ...sx,
      }}
    >
      <Box ref={collageRef} sx={{ position: "relative", width: "100%" }}>
        <CollageImageWrapper variant="big" sx={{}}>
          <Box as={GatsbyImage} image={firstImage.image} alt={firstImage.alt} />
          <Box
            as="span"
            sx={{
              display: ["none", "none", "block", "block"],
              marginTop: "1rem",
              color: get("palette.text.grey_gold"),
              fontFamily: "custom_42266",
              fontWeight: 400,
              fontSize: "16px",
            }}
          >
            {firstImage.description}
          </Box>
          <CollageImageWrapper
            style={{
              y: !isMobileOnly ? translateY : "40%",
            }}
            sx={{ right: ["-5%", "-10%", "-15%", "-15%"] }}
            variant="small"
          >
            <Box
              as={GatsbyImage}
              image={secondImage.image}
              alt={secondImage.alt}
            />
            <Box
              as="span"
              sx={{
                display: ["none", "none", "block", "block"],
                marginTop: "1rem",
                color: get("palette.text.grey_gold"),
                fontFamily: "custom_42266",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              {secondImage.description}
            </Box>
          </CollageImageWrapper>
        </CollageImageWrapper>
      </Box>
    </Box>
  );
};

export default React.memo(Collage);
