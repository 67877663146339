import { useTransform, useViewportScroll } from "framer-motion";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { clampBuilder } from "../../helpers/sizeCalc";
import useWindowSize from "../../hooks/useWindowSize";
import { Routes } from "../../routes";
import { HistorySectionQuery } from "../../types/pages/main";
import Box from "../Box/Box";
import MotionBox from "../Box/MotionBox";
import Collage from "../Collage";
import LinkArrowBtn from "../LinkArrowBtn";
import { SectionWrapper2 } from "../Styled/Containerts";
import { Paragraph, Paragraph2, Subtitle, Title } from "../Styled/Text";
import AppearAnimationContainer from "../Animations/AppearContainer";
import { get } from "../../constants/theme";
import VaseDesktop from "./history/animated/desktop";
import VaseMobile from "./history/animated/mobile";

interface Props {
  setVaseEnding: (offset: number) => void;
}

const History = (
  { setVaseEnding }: Props,
  ref: React.ForwardedRef<HTMLDivElement | null>
) => {
  const [isBgCover, setIsBgCover] = React.useState<boolean>(false);
  const [imgContainerSize, setImgContainerSize] = React.useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const [timelinePosition, setTimelinePosition] = React.useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });
  const [offset, setOffset] = React.useState<number>(0);
  const { scrollY } = useViewportScroll();
  const { width, height } = useWindowSize();

  const lastPostImageContainerRef = React.useCallback(
    (node: any | null) => {
      if (node !== null) {
        setOffset(node.offsetTop);
        setVaseEnding(node.offsetTop + 1350);
        setImgContainerSize({
          width: node.clientWidth,
          height: node.clientHeight,
        });
      }
    },
    [setVaseEnding]
  );

  const timelineStart = React.useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setTimelinePosition((prev) => ({
        ...prev,
        start: node.offsetTop + 50,
      }));
    }
  }, []);

  const timelineEnd = React.useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setTimelinePosition((prev) => ({
        ...prev,
        end: node.offsetTop,
      }));
    }
  }, []);

  const ringOpacity = useTransform(
    scrollY,
    [
      width < 768 ? offset + 250 : offset + 750,
      offset + 900,
      offset + 1100,
      offset + 1250,
    ],
    [0, 1, 1, 0]
  );

  const ringYTranslate = useTransform(
    scrollY,
    [width < 768 ? offset + 250 : offset + 700, offset + 850],
    ["0%", `${-147.5}px`]
  );

  const zIndex = useTransform(
    scrollY,
    [offset, offset + 1300, offset + 1350],
    [2, 2, -1]
  );

  const zIndexBg = useTransform(
    scrollY,
    [offset, offset + 1300, offset + 1600],
    [1, 1, -1]
  );

  const bgOpacity = useTransform(scrollY, [offset, offset + 500], [0, 1]);

  React.useEffect(() => {
    const sub = scrollY.onChange((v) => {
      if (width < 768) {
        if (v > offset) {
          setIsBgCover(true);
        } else {
          setIsBgCover(false);
        }
      } else {
        if (v > offset + 200) {
          setIsBgCover(true);
        } else {
          setIsBgCover(false);
        }
      }
    });

    return () => {
      sub();
    };
  }, [scrollY, offset, imgContainerSize, height, width]);

  const { title, subtitle, description, header } =
    useStaticQuery<HistorySectionQuery>(graphql`
      query HistorySectionQuery {
        translationsJson {
          pages {
            main {
              sections {
                history {
                  subtitle
                  title
                  description
                  header {
                    title
                    posts {
                      title
                      imageAlt
                      description
                      image {
                        childImageSharp {
                          gatsbyImageData
                          original {
                            width
                            height
                          }
                        }
                      }
                    }
                    link
                    images {
                      image {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                      imageAlt
                    }
                  }
                }
              }
            }
          }
        }
      }
    `).translationsJson.pages.main.sections.history;

  return (
    <Box sx={{ zIndex: 5 }}>
      <SectionWrapper2
        sx={{ zIndex: 2, display: "flex", justifyContent: "center" }}
      >
        <AppearAnimationContainer wrapperCmp={Subtitle}>
          {subtitle}
        </AppearAnimationContainer>
        <AppearAnimationContainer
          wrapperCmp={Title}
          variant="section"
          sx={{ textAlign: "center" }}
        >
          {title}
        </AppearAnimationContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AppearAnimationContainer
            wrapperCmp={Paragraph2}
            variant="normal"
            sx={{
              width: [
                "100%",
                "100%",
                clampBuilder(599, 1680, 19.1875, 39.625),
                clampBuilder(599, 1680, 19.1875, 39.625),
              ],
              textAlign: "center",
              padding: "0",
            }}
          >
            {description}
          </AppearAnimationContainer>
        </Box>
      </SectionWrapper2>
      <Box
        ref={timelineStart}
        sx={{
          width: 0,
          height: 0,
          background: "transparent",
          opacity: 0,
          visibility: "hidden",
        }}
      />
      <AppearAnimationContainer
        wrapperCmp={Title}
        sx={{
          width: "100%",
          textAlign: "center",
          marginBottom: [
            clampBuilder(320, 599, 2.75, 3),
            clampBuilder(320, 599, 2.75, 3),
            clampBuilder(599, 1680, 3.75, 9.6875),
            clampBuilder(599, 1680, 3.75, 9.6875),
          ],
        }}
        variant="fancy"
      >
        {header.title}
        <br />
        1721
      </AppearAnimationContainer>

      {width >= 768 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: [
              "column-reverse",
              "column-reverse",
              "column",
              "column",
            ],
            marginBottom: [
              clampBuilder(320, 599, 3.375, 6.6875),
              clampBuilder(320, 599, 3.375, 6.6875),
              0,
              0,
            ],
          }}
        >
          <Collage
            sx={{ zIndex: 2 }}
            firstImage={{
              image: header.images[0].image.childImageSharp.gatsbyImageData,
              alt: header.images[0].imageAlt,
              description: header.images[0].imageAlt,
            }}
            secondImage={{
              image: header.images[1].image.childImageSharp.gatsbyImageData,
              alt: header.images[1].imageAlt,
              description: header.images[1].imageAlt,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "row", "row"],
              justifyContent: "space-between",
              marginBottom: [
                clampBuilder(320, 599, 3.375, 6.6875),
                clampBuilder(320, 599, 3.375, 6.6875),
                0,
                0,
              ],
            }}
          ></Box>
        </Box>
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          alignItems: "center",
          flexDirection: ["column", "column", "row", "row"],
          justifyContent: "space-between",
          minHeight: [
            clampBuilder(599, 1680, 17.5, 31.8125),
            clampBuilder(599, 1680, 17.5, 31.8125),
          ],
          width: "100%",
          padding: "0 2.5%",
          "& > :nth-child(1n)": {
            flex: 1,
          },
          marginBottom: "2rem",
        }}
      >
        <AppearAnimationContainer
          wrapperCmp={Box}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gridColumnStart: [1, 2, 2, 2],
            gridColumnEnd: [13, 6, 6, 6],
            gridRowStart: [1, 1, 1, 1],
          }}
        >
          <Box
            sx={{
              maxWidth: [
                clampBuilder(320, 599, 4.625, 8.875),
                clampBuilder(320, 599, 4.625, 8.875),
                clampBuilder(599, 1680, 3.4375, 7.625),
                clampBuilder(599, 1680, 3.4375, 7.625),
              ],
              maxHeight: [
                clampBuilder(320, 599, 6.875, 13),
                clampBuilder(320, 599, 6.875, 13),
                clampBuilder(599, 1680, 5.625, 11.5625),
                clampBuilder(599, 1680, 5.625, 11.5625),
              ],
              marginBottom: ["2rem"],
            }}
            //sx={{ height: "84px", width: "58px" }}
            //sx={{ height: "185px", width: "122px" }}
            as={GatsbyImage}
            image={header.posts[0].image.childImageSharp.gatsbyImageData}
            alt=""
            loading="eager"
          />
        </AppearAnimationContainer>

        <Box
          sx={{
            display: "flex",
            gridColumnStart: [1, 2, 2, 2],
            gridColumnEnd: [13, 6, 6, 6],
            gridRowStart: [2, 2, 2, 2],
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 400,
            fontFamily: "custom_42264",
            color: get("palette.text.brown"),
            textAlign: ["left", "left", "center", "center"],
            fontSize: "1.5vmax",
            width: "100%",
            marginTop: [0, 0, 0, 0],
            marginBottom: ["20px", 0, 0, 0],
          }}
        >
          <AppearAnimationContainer
            wrapperCmp={Title}
            sx={{
              alignSelf: "center",

              textAlign: "center",
            }}
            variant="paragraph"
          >
            {header.posts[0].title}
          </AppearAnimationContainer>
          <AppearAnimationContainer
            wrapperCmp={Paragraph}
            sx={{
              textAlign: "center",

              alignSelf: "center",
              width: "100%",
            }}
          >
            {header.posts[0].description}
          </AppearAnimationContainer>
        </Box>
        <Box
          sx={{
            marginBottom: "20px",
            gridColumnStart: [1, 8, 8, 8],
            gridColumnEnd: [13, 12, 12, 12],
            gridRowStart: [3, 3, 3, 3],
          }}
        ></Box>
      </Box>

      {width < 768 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: [
              "column-reverse",
              "column-reverse",
              "column",
              "column",
            ],
          }}
        >
          <Collage
            sx={{ zIndex: 2 }}
            firstImage={{
              image: header.images[0].image.childImageSharp.gatsbyImageData,
              alt: header.images[0].imageAlt,
              description: header.images[0].imageAlt,
            }}
            secondImage={{
              image: header.images[1].image.childImageSharp.gatsbyImageData,
              alt: header.images[1].imageAlt,
              description: header.images[1].imageAlt,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "row", "row"],
              justifyContent: "space-between",
              marginBottom: [
                clampBuilder(320, 599, 3.375, 6.6875),
                clampBuilder(320, 599, 3.375, 6.6875),
                0,
                0,
              ],
            }}
          ></Box>
        </Box>
      )}

      <Box
        sx={{
          display: "grid",
          alignItems: "flex-start",
          gridTemplateColumns: "repeat(12, 1fr)",
          flexDirection: ["column", "column", "row", "row"],
          justifyContent: "space-between",
          minHeight: [
            clampBuilder(599, 1680, 17.5, 31.8125),
            clampBuilder(599, 1680, 17.5, 31.8125),
          ],
          width: "100%",
          padding: "0 2.5%",
          "& > :nth-child(1n)": {
            flex: 1,
          },
        }}
      >
        <Box
          ref={lastPostImageContainerRef as any}
          sx={{
            marginBottom: [0, 0, 0, 0],
            gridColumnStart: [1, 2, 2, 2],
            gridColumnEnd: [13, 6, 6, 6],
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          {width < 768 ? (
            <VaseMobile
              imgOriginalSize={{
                width: header.posts[1].image.childImageSharp.original.width,
                height: header.posts[1].image.childImageSharp.original.height,
              }}
              img={header.posts[1].image.childImageSharp.gatsbyImageData}
              imgAlt={header.posts[1].imageAlt}
            />
          ) : (
            <VaseDesktop
              imgContainerSize={{
                width: imgContainerSize.width,
                height: imgContainerSize.height,
              }}
              imgOriginalSize={{
                width: header.posts[1].image.childImageSharp.original.width,
                height: header.posts[1].image.childImageSharp.original.height,
              }}
              img={header.posts[1].image.childImageSharp.gatsbyImageData}
              imgAlt={header.posts[1].imageAlt}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gridColumnStart: [1, 8, 8, 8],
            gridColumnEnd: [13, 12, 12, 12],
            gridRowStart: [1, 1, 1, 1],
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 400,
            fontFamily: "custom_42264",
            color: get("palette.text.brown"),
            textAlign: ["center", "center", "center", "center"],
            fontSize: "1.5vmax",
            width: "100%",
            marginTop: ["80px", 0, 0, 0],
            marginBottom: ["20px", 0, 0, 0],
          }}
        >
          <AppearAnimationContainer
            wrapperCmp={Title}
            sx={{
              alignSelf: "center",
              textAlign: "center",
            }}
            variant="paragraph"
          >
            {header.posts[1].title}
          </AppearAnimationContainer>
          <AppearAnimationContainer
            wrapperCmp={Paragraph}
            sx={{
              textAlign: "center",
              alignSelf: "center",
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            {header.posts[1].description}
          </AppearAnimationContainer>
          <AppearAnimationContainer
            triggerOffset={-100}
            sx={{ justifyContent: ["left"] }}
          >
            <LinkArrowBtn title={header.link} to={Routes.HISTORY} as={Link} />
          </AppearAnimationContainer>
        </Box>
      </Box>
      <Box
        ref={timelineEnd}
        sx={{
          width: 0,
          height: 0,
          background: "transparent",
          opacity: 0,
          visibility: "hidden",
        }}
      />
      <Box ref={ref} sx={{ minHeight: "1300px" }} />

      <Box
        sx={{
          position: "absolute",
          top: timelinePosition.start,
          left: "50%",
          minHeight: `${
            timelinePosition.end - timelinePosition.start + height * 0.5
          }px`,
          width: "0.5px",
          background: get("palette.text.dark_gold"),
          zIndex: 1,
          marginTop: [
            clampBuilder(320, 599, 2.75, 3),
            clampBuilder(320, 599, 2.75, 3),
            clampBuilder(599, 1680, 3.75, 9.6875),
            clampBuilder(599, 1680, 3.75, 9.6875),
          ],
          display: ["none", "none", "none", "block"],
          // transform: `translateY(-${height * 0.05}px)`,
        }}
      >
        <Box
          sx={{
            left: "50%",
            position: "absolute",
            width: "6px",
            height: "6px",
            transform: "rotate(45deg)",
            background: get("palette.text.dark_gold"),
            marginLeft: "-3px",
          }}
        />
      </Box>
      <MotionBox
        sx={{
          position: "fixed",
          top: `${height / 2}px`,
          left: "50%",
          width: 295,
          height: 295,
          borderRadius: "50%",
          border: "1px solid white",
          background: "transparent",
          transform: "translateX(-50%)",
        }}
        style={{
          opacity: ringOpacity as any,
          scale: width < 768 ? 0.75 : 1,
          x: "-50%",
          zIndex: zIndex as any,
          marginTop: ringYTranslate as any,
        }}
      >
        <MotionBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "75%",
            height: "75%",
            borderRadius: "50%",
            border: "1px solid white",
            transform: "translateX(-50%) translateY(-50%)",
          }}
        />
      </MotionBox>
      {isBgCover && (
        <MotionBox
          sx={{
            position: "fixed",
            left: 0,
            top: 0,
            height: "100%",
            width: width,
            background: get("palette.background.gold"),
          }}
          style={{ opacity: bgOpacity as any, zIndex: zIndexBg as any }}
        />
      )}
    </Box>
  );
};

export default React.memo(React.forwardRef<HTMLDivElement, Props>(History));
