import React from "react";
import { isMobile } from "react-device-detect";

function usePageReload() {
  const refreshPage = React.useCallback(() => {
    !isMobile && window.location.reload();
  }, []);
  React.useEffect(() => {
    window.addEventListener("resize", refreshPage);

    return () => {
      window.removeEventListener("resize", refreshPage);
    };
  }, [refreshPage]);
}

export default usePageReload;
